.Login {
  overflow-y: auto;
}
.Login .welcome {
  background: #efecec;
}
@media (max-width: 1000px) {
  .Login,
  .Login .welcome,
  .Login .actions,
  .Login .actions .auth-option {
    display: block;
  }
}
@media (min-width: 1001px) {
  .Login .welcome {
    padding-top: 150px;
  } 
}
@media (max-width: 1919px) {
  .Login .actions {
    padding: 20px 20px 0 20px;
  }
  .Login .actions .auth-options > div {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .Login .actions .or-box {
    padding: 0;
  }
}
.Login .actions .new {
  background: #efecec;
}
.Login .actions .or-box {
  height: 100%;
}
.Login .actions .or-box .or-line{
  width: 2px;
}
.Login .actions .auth-options {
  text-align: center;
  min-height: 100%;
}
.Login .actions .auth-option {
  background: #efecec;
  min-height: 100%;
  display: block;
}
.Login .Barcode .canvas {
  width: 400px;
  max-width: 95%;
  margin: 0 auto;
}
.Login .Barcode .canvas,
.Login .Barcode .outputData {
  display: none;
}
.Login .Barcode.loaded .canvas
/*, .Login .Barcode.loaded .outputData  */
{
  display: block;
}
